import React, { useContext, useEffect, useRef, useState } from 'react';
import PlanningContext from '@context/PlanningContext';
import congesService from '@data/congesService';
import dayjs from 'dayjs';
import { flutterColorToHexa } from '@utility/Utils';
import UserContext from '@context/UserContext';

import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const PlanningBody = ({ setSelectedLeave, setIsModalOpen }) => {
  const {
    usersGroupedBySite,
    daysOfPeriod,
    selectedModels,
    users,
    reversePrimary,
    publicHolidays,
    hoverUser,
  } = useContext(PlanningContext);
  const [leaves, setLeaves] = useState([]);
  const { currentUser } = useContext(UserContext);
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      fetchLeaves();
    } else {
      isMounted.current = true;
    }
  }, [usersGroupedBySite, daysOfPeriod]);

  const fetchLeaves = async () => {
    try {
      if (daysOfPeriod.length > 0) {
        const firstDay = daysOfPeriod[0].format('YYYY-MM-DD');
        const lastDay = daysOfPeriod[daysOfPeriod.length - 1]
          .add(1, 'day')
          .format('YYYY-MM-DD');
        const res = await congesService.post(
          `/v1/leaves/search?include=leave_type,user,user.validators,status,histories.user,histories.status&limit=1000`,
          {
            filters: [
              {
                nested: [
                  {
                    field: 'user_id',
                    operator: 'in',
                    value: users?.map((u) => u.id),
                  },
                ],
              },
              {
                nested: [
                  {
                    type: 'or',
                    nested: [
                      { field: 'start_date', operator: '>=', value: firstDay },
                      { field: 'start_date', operator: '<=', value: lastDay },
                    ],
                  },
                  {
                    type: 'or',
                    nested: [
                      { field: 'end_date', operator: '>=', value: firstDay },
                      { field: 'end_date', operator: '<=', value: lastDay },
                    ],
                  },
                  {
                    type: 'or',
                    nested: [
                      { field: 'start_date', operator: '<=', value: firstDay },
                      { field: 'end_date', operator: '>=', value: lastDay },
                    ],
                  },
                ],
              },
              {
                field: 'status.tag',
                operator: 'not in',
                value: ['CANCELED', 'REFUSED'],
              },
            ],
            sort: [
              {
                field: 'start_date',
                direction: 'asc',
              },
            ],
          }
        );

        setLeaves(
          res.data.data.map((leave) => {
            return {
              ...leave,
              leave_type: {
                ...leave.leave_type,
                color: leave.leave_type.color.replace(/0xFF|0xff/, '#'),
              },
            };
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getLeaveAtThisDate = (leaveList, date) => {
    let leave = [];
    if (leaveList.length !== 0) {
      //Check if user has leave at this date
      for (const element of leaveList) {
        const hasLeave = date.isBetween(
          dayjs(element.start_date),
          dayjs(element.end_date),
          'day',
          '[]'
        );

        if (hasLeave) {
          leave.push(element);
          if (dayjs(date).diff(element.start_date, 'day') > 12) break;
        }

        if (leave.length == 2) {
          break;
        }
      }

      if (
        leave.length == 1 &&
        dayjs(leave[0].end_date).isSame(date, 'day') &&
        dayjs(leave[0].end_date).hour() === 12
      ) {
        leave.push(null);
      }

      if (
        leave.length == 1 &&
        dayjs(leave[0].start_date).isSame(date, 'day') &&
        dayjs(leave[0].start_date).hour() === 12
      ) {
        leave = [null, ...leave];
      }
    }
    return leave;
  };

  const openDayColor = (user, date) => {
    let openDays = [];
    if (publicHolidays.some((e) => dayjs(e.date).isSame(date, 'day'))) {
      return '#D3D3D3';
    }

    if (
      currentUser?.client.is_allowed_to_modify_open_days &&
      user.days?.length > 0
    ) {
      let formattedDate;
      if (parseInt(dayjs(date).format('d')) === 0) {
        formattedDate = 7;
      } else {
        formattedDate = parseInt(dayjs(date).format('d'));
      }

      //public holiday color
      if (!user.days.includes(formattedDate)) {
        return '#D3D3D3';
      }
    } else {
      openDays = currentUser.client_days;

      //Weekend color
      if (openDays?.length > 0 && !openDays.includes(date.day())) {
        return '#F2F2F2';
      }
    }
  };

  const renderCell = (leave, user, day, currentOpenDayColor) => {
    if (leave == null || currentOpenDayColor != null) {
      return (
        <td
          key={`cell_${user.id}_${day.$D}`}
          style={{
            width: '200px',
            height: '100%',
          }}
        ></td>
      );
    }

    return (
      <td
        key={`cell_${leave.id}_${user.id}_${day.$D}`}
        style={{
          width: '200px',
          height: '100%',
        }}
        onClick={() => {
          setSelectedLeave(leave);
          setIsModalOpen(true);
        }}
      >
        <div
          style={{
            background: reversePrimary
              ? leave?.leave_type?.color
              : flutterColorToHexa(leave?.status?.color),
            borderBottom: `5px solid ${
              reversePrimary
                ? flutterColorToHexa(leave?.status?.color)
                : leave?.leave_type?.color
            }`,
            borderTopLeftRadius: dayjs(leave.start_date).isSame(day, 'day')
              ? '4px'
              : '0',
            borderBottomLeftRadius: dayjs(leave.start_date).isSame(day, 'day')
              ? '4px'
              : '0',
            borderTopRightRadius: dayjs(leave.end_date).isSame(day, 'day')
              ? '4px'
              : '0',
            borderBottomRightRadius: dayjs(leave.end_date).isSame(day, 'day')
              ? '4px'
              : '0',
          }}
        >
          &nbsp;
        </div>
      </td>
    );
  };

  return usersGroupedBySite.map((siteWithUsers) => {
    //Display only tags selected in filter
    //because an user can have multiple tags
    if (
      selectedModels.length === 0 ||
      selectedModels.some(
        (modelId) => siteWithUsers.name.split('_')[2] === modelId
      )
    ) {
      return (
        <div key={siteWithUsers.name}>
          <div className={`w-full h-8 visible}`}></div>
          {
            <table
              className='w-full'
              style={{
                tableLayout: 'fixed',
                emptyCells: 'show',
                borderCollapse: 'collapse',
              }}
            >
              <tbody>
                {siteWithUsers.users.map((user) => {
                  const userLeaves = leaves.filter(
                    (l) => l.user_id === user.id
                  );

                  return (
                    <tr
                      key={`row_${user.id}`}
                      style={{
                        height: '30px',
                      }}
                    >
                      {daysOfPeriod.map((day, i) => {
                        const dayLeaves = getLeaveAtThisDate(userLeaves, day);
                        const currentOpenDayColor = openDayColor(user, day);
                        return (
                          <th
                            key={`cell_${user.id}_${i}`}
                            style={{
                              border: '1px solid #F2F2F2',
                              background:
                                hoverUser?.id === user.id
                                  ? 'rgb(88 17 26 / 15%)'
                                  : currentOpenDayColor ?? '#FFFFFF',
                              cursor: dayLeaves.length ? 'pointer' : 'default',
                              position: 'relative',
                            }}
                          >
                            {dayjs(day).isSame(dayjs(), 'day') && (
                              <td
                                style={{
                                  borderRight: '3px dashed red',
                                  width: 3,
                                  height: '100%',
                                  position: 'absolute',
                                  top: 0,
                                  right: '50%',
                                }}
                              />
                            )}
                            {dayLeaves.map((leave) =>
                              renderCell(leave, user, day, currentOpenDayColor)
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
        </div>
      );
    }
  });
};

export default PlanningBody;
